import { createSlice } from "@reduxjs/toolkit";

const potentialSlice = createSlice({
    name: 'potential',
    initialState: {
        contractorId : 0,
        jobId : 0,
        eventLog: "",
    },
    reducers: 
    {
        ContractorIdAndJobId (state, action) {
            return {...state,contractorId : action.payload.Ids.contractorId , jobId: action.payload.Ids.jobId };
        },
        SetEventLog(state, action) {
            state.eventLog = action.payload;
        },
        ClearEventLog(state) {
            state.eventLog = "";
        }
    }
})

export const { ContractorIdAndJobId, SetEventLog, ClearEventLog } = potentialSlice.actions
export default potentialSlice.reducer