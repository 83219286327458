import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import * as typeAction from '../constant/actionType';
import * as api from './../../utils/api';
// import { toastr } from "react-redux-toastr";

export const getUnreadEmails = createAsyncThunk(
    'websockets/getUnreadEmails',
    async() => {
        return await api.getUnreadEmails()
        .then(response => {
            // console.log("state of response", response.data.data);
            // if(response.data.data.some(x => x.read))
            // {
            //     console.log("some of the repsonse is not read");
            //     //toastr.success("You've got mail!", `You have ${response.filter(x => !x.read).length} unread emails in your inbox`);
            //     console.log("Other side of toastr");
            // }
            // console.log("Justr before Return");
            return response.data.data;
        });

    },
)

const websocketSlice = createSlice({
    name:'websockets',
    initialState: {
        taskList: [],
        emailList: [],
        sidebarConfig: "",
    },
    reducers: 
    {
        openLeftSideBar(state, action) {
            return {...state, isOpenLeftSide: action.payload};
        },
        taskListClear(state, action){
            return {...state, isLoading: action.payload}
        },
        // getUnreadEmailList(state){
        //     console.log("email Update!");
        //     const response = api.getUnreadEmails();
        //     console.log(response);
        //     if (response) {
        //         const emailList = response.data.data;
        //         console.log("Email list is now", emailList);
        //         return {...state, emailList: emailList};
        //     }
        // },
        clearUnreadEmailList(state){
            console.log("email Clear!");
            return {...state, emailList: []};
        },

    },
    extraReducers: (builder) => {
        builder.addCase(getUnreadEmails.pending, (state, action) => {
            console.log("get Unread emails pending");
        });
        builder.addCase(getUnreadEmails.fulfilled, (state, action) => {
            state.emailList = action.payload;
        });
    },
})

export const { openLeftSideBar, taskListClear, clearUnreadEmailList } = websocketSlice.actions;
export default websocketSlice.reducer;