import { useState } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { baseUrl } from './../utils/api';
import { getToken } from './../navigation/LocalStorageFacade';
import * as actionWebsockets from './../redux/actions/actionWebsockets';
import {clearUnreadEmailList, getUnreadEmails} from './../redux/newReduxActions/newWebsockets';

function Websockets(props) {
    const { getTaskList, clearTaskList} = props;

    const user = useSelector(state => state.user.user);
    const userId = user.id;

    const dispatch = useDispatch();

    const [hub, setHub] = useState();

    //----------------------------------------------------------------------- Start websocket comms
    const startHub = () => {
        const nextRetryInterval = (retryContext) => {
            const count = retryContext.previousRetryCount;
            const nextInterval = 1000 * Math.min(120, (0.2 * count + 2.0) * count);
            return nextInterval;
        }
        const retryManager = {nextRetryDelayInMilliseconds: nextRetryInterval};

        const token = getToken();

        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${baseUrl}/clientnotifier?user=${userId}`, {accessTokenFactory: () => token})
            .configureLogging(LogLevel.Critical)
            .withAutomaticReconnect(retryManager)
            .build();

        hubConnection.keepAliveIntervalInMilliseconds = 40000; // 40 seconds
        hubConnection.serverTimeoutInMilliseconds = 120000; // 2 minutes

        hubConnection.on('TaskListChanged', () => getTaskList);
        hubConnection.on('EmailListChanged', () => dispatch(getUnreadEmails()));

        hubConnection.start()
            .then(() => {
                hubConnection.send('NotifyClient', userId); // Do initial download on startup
            })
            .catch(err => {});

        setHub(hubConnection);
    }

    //------------------------------------------------------------------------ Stop websocket comms
    const stopHub = () => {
        hub.stop()
            .then(() => {
                clearTaskList();
                dispatch(clearUnreadEmailList());
                setHub(null);
            })
            .catch(err => {});
    }

    //--------------------------------------------------- Decide whether to start or stop the comms
    if (userId && !hub) startHub();             // there is a logged-in user and the hub is stopped
    if (!userId && hub) stopHub();                 // there is no user but the hub is still running

    //--- This component has no UI ---
    return null;
}

const mapDispatchToProps = (dispatch) => ({
    getTaskList:() => dispatch(actionWebsockets.getTaskList()),
    clearTaskList:() => dispatch(actionWebsockets.clearTaskList()),
})

export default connect(mapDispatchToProps)(Websockets);