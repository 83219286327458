import { createSlice } from "@reduxjs/toolkit";
// import { useSelector } from "react-redux";
// import * as typeAction from '../constant/actionType';
import * as api from '../../utils/api';
const userSlice = createSlice({
    name:'user',
    initialState: {
        user: {},
        sidebarConfig: null,
    },
    reducers: 
    {
        setCurrentUser(state, action) {
            state.user = (action.payload);
        },
        updateCurrentUser(state, action){
            const newUser = ({...state.user, ...action.payload});
            state.user = newUser;
        },
        getCurrentUser(state, action){
            return {...state.user.user};
        },
        updateUserPassword(password){
            return api.updatePassword(password);
        },
        signoutUser(state){
            state.user = { };
        },
        setSidebarConfig(state, action){
            return {...state, sidebarConfig: action.payload}
        }
    }
});

export const { setCurrentUser, getCurrentUser, updateCurrentUser, updateUserPassword, signoutUser, setSidebarConfig } = userSlice.actions;

export default userSlice.reducer;