import { createSlice } from "@reduxjs/toolkit";


const userManageSlice = createSlice({
    name:'manageUser',
    initialState: {},
    reducers: 
    {
        getAllUsers(state, action) {
            return {
            ...state,
            message: 'get users success',
            user: action.user,
            };
        },
        getUser(state, action){
            return {
                ...state,
                message: 'get user success',
                user: [
                    action.user
                ]
           }        
        },
        getRegions(state, action){
            return {
                ...state,
                message: 'get regions success',
                region: action.region,
           }        
        },
        getStatus(state, action){
            return {
                ...state,
                message: 'get status success',
                status: action.status
           }        
        },
        addUser(state, action){
            let newState = state.user;
            return {
                ...state,
                user: [
                    ...newState,
                    action.user
                ]
            }
        },
        deleteUsers(state, action){
            let newState = state.user.filter(x => x.id !== action.id);
            return {
                ...state,
                user: [
                    ...newState
                ]
            }
        }
    }
})

export const { getAllUsers, getUser, getRegions, getStatus, 
    addUser, deleteUsers} = userManageSlice.actions;

export default userManageSlice.reducer;