import { useState, useEffect } from 'react';

function useApplicationInstance() {

    const [instance, setInstance] = useState({});

    useEffect(() => {
        const domain = window.location.hostname.toLowerCase();

        const CPBMS  = {site:'NECIT', menu:'NECIT', production:false, appName:'Inspekta Gadget', class:'necit-app', mapKey:'AIzaSyDSS96SM1uH_D6cJ7BQF2daf9MWL7uvUuc', syncMaster:true };
        const GADGET = {site:'NECIT', menu:'NECIT', production:true,  appName:'Inspekta Gadget', class:'necit-app', mapKey:'AIzaSyA0LotZXXDOKO8k_z6Xx1885JGJXG2G_9A', syncMaster:true };
        const CPION  = {site:'WDC',   menu:'WDC',   production:false, appName:'ION',             class:'wdc-app',   mapKey:'AIzaSyDbI0cnEbsppZSW3hEOZLYxoP1OXkvjWVI', syncMaster:false};
        const ION    = {site:'WDC',   menu:'WDC',   production:true,  appName:'ION',             class:'wdc-app',   mapKey:'AIzaSyBsD5KVBzXBZhypMRYLHdtFHZqf0cTHOlM', syncMaster:false};
        const DEV    = {site:'DEV',   menu:'DEV',   production:false, appName:'IONspekta',       class:'dev-app',   mapKey:'AIzaSyDSS96SM1uH_D6cJ7BQF2daf9MWL7uvUuc', syncMaster:true };
        const NULL   = {site:null,    menu:'DEV',   production:false, appName:null,              class:'dev-app',   mapKey:'AIzaSyBhCzmHJu34Y4UEJc211k6dDwZrHU66wgA', syncMaster:false};
            
        switch (domain) {
            case 'gadgettest.azurewebsites.net': setInstance(CPBMS); break;

            case 'cpbms.azurewebsites.net':     setInstance(CPBMS); break;

            case 'plexusbms.azurewebsites.net': // deliberate fall-through
            case 'gadget.necitservices.com':    setInstance(GADGET); break;

            case 'cpion.azurewebsites.net':     setInstance(CPION); break;

            case 'wdcion.azurewebsites.net':    // deliberate fall-through
            case 'ion.thewdc.co.uk':            setInstance(ION); break;

            case 'localhost':                   setInstance(DEV); break;
            
            default:                            setInstance(NULL); break;        
        }

    }, []);

    return instance;
}

export default useApplicationInstance;