/*
 * Helpers for working with local storage
 *
 * Note that users can disable localstorage in their browsers, which will stop this feature
 * working.  So a utility to test its availability is also included.
 * 
 */

/* 
 * Check is local storage is aavilable.
 * Returns true if storage can be used.
 */
export const checkStorageAvailable = () => {
    var storage;
    try {
        storage = localStorage;
        var test = 'zyd0c2inh7'; // just a random string
        storage.setItem(test,test);
        storage.removeItem(test);
        return true;

    } catch(e) {
        return false;
        // Technically, the error may be that the local storage is full rather than unavailable.
        // This is unlikely given our intended use of it, and still makes it unusable.
    }
}

/*
 * TOKEN
 */
export const getToken = () => { return getFromStorage(LOCALSTORE_TOKEN); }
export const clearToken = () => { return removeFromStorage(LOCALSTORE_TOKEN); }
export const setToken = () => { return setFromStorage(LOCALSTORE_TOKEN); }

/*
 * FORCE PASSWORD RESET CODE
 */
export const getPasswordCode = () => { return getFromStorage(LOCALSTORE_FORCE_PWD_CODE); }
export const clearPasswordCode = () => { return removeFromStorage(LOCALSTORE_FORCE_PWD_CODE); }
export const setPasswordCode = () => { return setFromStorage(LOCALSTORE_FORCE_PWD_CODE); }



// Private section --------------------------------------------------------------------------------
const LOCALSTORE_TOKEN = 'token';
const LOCALSTORE_FORCE_PWD_CODE = 'forcePasswordCode';

const getFromStorage = (key) => {
    return localStorage.getItem(key);
}

const removeFromStorage = (key) => {
    localStorage.removeItem(key);
}

const setFromStorage = (key, value) => {
    localStorage.setItem(key, value);
}
