import React from 'react';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';
import { store, persistor } from './redux/newReduxActions/newIndex';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import App from './App';
import Websockets from './utils/Websockets';
import { PersistGate } from 'redux-persist/integration/react';

import './styles/app.scss';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />   
        </PersistGate>
        <ReduxToastr
            timeOut={4000}
            newestOnTop={true}
            preventDuplicates
            position='top-left'
            getState={(state) => state.toastr}
            transitionIn='fadeIn'
            transitionOut='fadeOut'
            progressBar
            closeOnToastrClick/>
        <Websockets/>
    </Provider>
    );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();