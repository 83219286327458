import React, { Suspense } from 'react';
import { RouterProvider, createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import routes from './newRouterConfig';

import { Skeleton } from 'antd';
import { getToken } from '../navigation/LocalStorageFacade';

function Routers({}) 
{

    // case in-sensitive comparison of array. true if role is in array
    const arrayContainsRole = (arr, role) => {
        if (arr && role) {
            const cleanRole = role.trim().toLowerCase();
            const cleanArray = arr.map(x => x ? x.trim().toLowerCase() : []);

            const matchedRoles = cleanArray.filter(x => x === cleanRole);
            return 0 < matchedRoles.length;

        } else {
            return false;
        }
    }

    //const FAILACTION_HOME = 'HOME';
    const FAILACTION_LOGOUT = 'LOGOUT';
    // const FAILACTION_NONE = "NONE";

    const getAuthUser = () => {
        const result = { isValid: false, role : null, id : null, task: null}; // assume that user is not authorized

        const token = getToken();
        
        if (token && token !== 'undefined') { // user is authorized
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
                result.isValid = true;
                result.role = decodedToken.role;
                result.id = decodedToken.nameid;
                result.task = decodedToken.task;
        }
        return result;

    }


    const canViewPage = () => {

        const routeObj = routes[0].children
        .find(x => window.location.href.includes(x.path));
        //Anything outside of the first routes is public
        if(routeObj && !routeObj.public)
        {
            const roles = routeObj.roles;
            const routeTasks = routeObj.tasks;

            const authUser = getAuthUser();

            //console.log("Auth User", authUser);

            if (!authUser.isValid) {
                
                // unauthenticated user trying to see secured page
                // logout();
                routers.navigate("/");
                //window.location.reload();
            }
    
            // if (failAction === FAILACTION_HOME) { 
            //     // authenticated user trying to view public page
            //     redirect(`/home`);
            // } 

            // if(routeTasks !== undefined && routeTasks !== null)
            // {
            //     if (!arrayContainsRole(routeTasks, authUser.task))
            //     routers.navigate("/home");
            // }
    
            // if (roles) {
            //     // user is Admin or has permitted role
            //     if (authUser.role === 'Admin' || arrayContainsRole(roles, authUser.role))
            //     return "true";
            // } else {
            //     // if no roles are defined for route, then everyone can view it ... if they have any role
            //     if(authUser.role != null)
            //     return "true";
            //     //else return "false";
            // }
        }
    }
    
   //-------------------------------------------------------------------- Public Routes
   const publicRoute = (routerConfigObj, index) => 
   {
       return (
           <Route key={index} loader={() => document.title = routerConfigObj.title} 
           path={routerConfigObj.path} element={routerConfigObj.element}/>
       )
   }

   //-------------------------------------------------------------------- Private Routes (Is the user logged in at least?)

    const privateRoute = (routerConfigObj, index) => 
    {
        const unauthorizedPage = <Skeleton active={true} avatar={true} paragraph={{rows:7}}/>

        return (
            <Route key={index}  loader={() => document.title = routerConfigObj.title} 
            index={routerConfigObj.index} path={routerConfigObj.path} element={routerConfigObj.element}/>
        )
    }

    const newRoutes = routes.map((route, i) => {
        if(route.children !== undefined)
        {
            let childArray = [];
            childArray.push(route.children.map(((subRoute, i) => subRoute.public
            ? publicRoute(subRoute, i)
            : privateRoute(subRoute, i))));
            return<Route children={childArray} element={route.element}/>
        }
        else
        {
            return(route.public
                ? publicRoute(route, i)
                : privateRoute(route, i))
        }
    });

    
    // const testrouters = createHashRouter(routes);

    const routers = createHashRouter(
        createRoutesFromElements(newRoutes)
    );

    if(routers != null)
    {
        canViewPage();
    }

    return (
        <Suspense fallback={null}>
            <RouterProvider router={routers} />
        </Suspense>
    )
}

export default Routers;