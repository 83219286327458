export const ROLE_ADMIN = 'Admin';
export const ROLE_COMPANY_MANAGER = 'CompanyManager';
export const ROLE_REGIONAL_MANAGER = 'RegionalManager';
export const ROLE_COORDINATOR = 'Coordinator';
export const ROLE_CONTRACTOR = 'Contractor';
export const ROLE_CANDIDATE = 'Candidate';

export const ROLE_ADMIN_ID = 1;
export const ROLE_COMPANY_MANAGER_ID = 2;
export const ROLE_REGIONAL_MANAGER_ID = 3;
export const ROLE_COORDINATOR_ID = 4;
export const ROLE_CONTRACTOR_ID = 5;
export const ROLE_CANDIDATE_ID = 6;

export const ENTITY_CONTRACTOR = 1;
export const ENTITY_JOB = 2;
export const ENTITY_SUPPLIER = 3;
export const ENTITY_VENDOR = 4;
export const ENTITY_SERVICE_ORDER_TRACKER = 5;
export const ENTITY_CONTRACTOR_POTENTIAL = 6;
export const ENTITY_JOB_REPORT_TRACKER = 7;
export const ENTITY_CLIENT = 8;
export const ENTITY_TIMESHEET_TRACKER = 9;
export const ENTITY_APPLICATION_TRACKER = 10;
export const ENTITY_TIMESHEET_ENTRY = 11;
export const ENTITY_CONTRACTOR_INVOICE = 12;
export const ENTITY_WELD_ENQUIRY = 13;
export const ENTITY_WELD_JOB = 14;
export const ENTITY_ASSET = 16;

export const PASSWORD_MIN_LENGTH = 7;
export const PASSWORD_FORMAT = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[\S]+$/      // 1 each of upper/lower/digit

export const EMAIL_FORMAT = /^[a-zA-Z0-9_\-.]+@[a-zA-Z0-9_\-.]+$/   // x@x