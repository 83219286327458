import React, { useState, useEffect, useCallback } from 'react';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import classNames from 'classnames';

// import Header from  './common-components/Header/Header';
// import Sidebar from './common-components/Sidebar/Sidebar';
import Routers from './navigation/Routers';
import { checkStorageAvailable, getToken } from './navigation/LocalStorageFacade';
import * as actionUser from './redux/actions/actionUser';

function App(props) {
    const { setting, signOut } = props;

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [expiryTime, setExpiryTime] = useState(0);

    const getAuthUser = useCallback(() => {
        const result = { isValid: false, role : null, id : null, }; // assume that user is not authorized

        const token = getToken();
        if (token && token !== 'undefined') { // user is authorized
            const decodedToken = JSON.parse(atob(token.split('.')[1]));
            const tokenExpiresTime = (decodedToken.exp - 300) * 1000; //ms.  Finish 5 mins before token actually expires
            const currentTime = new Date().getTime();

            if (currentTime < tokenExpiresTime) { // user is authorized and token is still valid

                if (expiryTime !== tokenExpiresTime) setExpiryTime(tokenExpiresTime);  // capture absolute time when token expires

                setIsLoggingOut(false);
                setIsLoggedIn(true);
                result.isValid = true;
                result.role = decodedToken.role;
                result.id = decodedToken.nameid;
            }
        }
        return result;

    }, [])

    useEffect(() => {
        getAuthUser();
    }, [getAuthUser]);

    if(!checkStorageAvailable()) {
        return <div className='system-notice-page'>
          <h1>Application Cannot Proceeed</h1>
          <h2>Local Data Storage is disabled</h2>
          <p>This application needs to use the local data storage feature of your browser and will not
             work without it. This is used to store your login authorisation while you are working with
             the application. The data is deleted when you log out.</p>
          <p>The application has detected that local data storage has been disabled for your browser. 
             This can happen if you have blocked ALL cookies in your browser, or if you have used 
             advanced settings to disable local web storage.</p>
          <p>Please use the browser settings to re-enable local storage.</p>
        </div>
    }
    
    const containerClasses = classNames(
        'fade in', 
        {
            'page-sidebar-fixed': isLoggedIn,
            'page-sidebar-toggled': setting.isOpenLeftSide
        }
    );

    return (
        <div className='app'>
            <div id='page-container' className={containerClasses}>
{/*                <Header isLoggedIn={isLoggedIn} logout={logout} />
                <Sidebar isLoggedIn={isLoggedIn} /> */}
                <Routers checkAuthentication={getAuthUser} />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    setting: state.setting
});

const mapDispatchToProps = (dispatch) => ({
    signOut:() => dispatch(actionUser.signOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);