import * as actionType from '../constant/actionType';
import * as api from './../../utils/api';

//------------------------------------------------------------------------------------------- Tasks

export const getTaskList = () => {
    return async dispatch => {
        try {
            const response = null;
            if (response) {
                const taskList = response.data.data.tasks
                    .sort((a,b) => b.priority - a.priority) //descending priority
                dispatch(refreshTaskList(taskList));
            }
        } catch(err) {
        }
    }
}

export const clearTaskList = () => {
    return refreshTaskList([]);
}

export const refreshTaskList = (taskList) => {
    return {
        type: actionType.TASK_LIST_UPDATE,
        tasks: taskList,
    }
}

//------------------------------------------------------------------------------------------ Emails

export const getEmailList = () => {
    return async dispatch => {
        try {
            const response = await api.getUnreadEmails();
            if (response) {
                const emailList = response.data.data;
                dispatch(refreshEmailList(emailList));
            }
        } catch(err) {
        }
    }
}

export const clearEmailList = () => {
    return refreshEmailList([]);
}

export const refreshEmailList = (emailList) => {
    return {
        type: actionType.EMAIL_LIST_UPDATE,
        emails: emailList,
    }
}
