import { createSlice } from "@reduxjs/toolkit";
// import * as typeAction from '../constant/actionType';

const settingsSlice = createSlice({
    name:'user',
    initialState: {
        isOpenLeftSide: false,
        isLoading: false,
        apiCallsInProgress: 0,
    },
    reducers: 
    {
        openLeftSideBar(state, action) {
            return {...state, isOpenLeftSide: action.isOpenLeftSide};
        },
        setLoading(state, action){
            return {...state, isLoading: action.isLoading}
        },
        startApiCall(state){
            return {...state, apiCallsInProgress: state.apiCallsInProgress + 1};
        },
        stopApiCall(state){
            return {...state, apiCallsInProgress: state.apiCallsInProgress - 1};
        }
    }
})

export const { openLeftSideBar, setLoading, startApiCall, stopApiCall } = settingsSlice.actions;
export default settingsSlice.reducer;