import { configureStore } from "@reduxjs/toolkit";
import newEditContractorPotential from "./newEditContractorPotential";
import newSettingsActions from "./newSettingsActions";
import newUserActions from "./newUserActions";
import newUserManagement from "./newUserManagement";
import newWebsockets from "./newWebsockets";
import { reducer as toastrReducer } from "react-redux-toastr";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig ={
    key: 'user',
    storage,
}

const persistedUser = persistReducer(persistConfig, newUserActions);

export const store = configureStore({
    reducer: {
        user: persistedUser,
        userManage: newUserManagement,
        contractorPotential: newEditContractorPotential,
        setting: newSettingsActions,
        websockets: newWebsockets,
        toastr: toastrReducer
    }
});

export const persistor = persistStore(store);



